import { defineStore } from 'pinia'
import { shallowRef } from 'vue'

interface InsightsResponse {
  data: Post[]
}

export const useInsightsStore = defineStore({
  id: 'insightsStore',
  state: () => ({
    data: shallowRef([]) as unknown as InsightsResponse['data'],
    loading: false,
    lastFetch: 0
  }),
  actions: {
    async fetch() {
      const CACHE_TIME = 5 * 60 * 1000;
      if (this.data.length && Date.now() - this.lastFetch < CACHE_TIME) {
        return this.data;
      }

      try {
        this.loading = true;
        const config = useRuntimeConfig();
        const baseUrl = config.public.CMS_PRODUCTION_BASE_URL || process.env.CMS_PRODUCTION_BASE_URL;

        const response = await $fetch<InsightsResponse>(`${baseUrl}/insights?populate=*`, {
          timeout: 10000
        });

        this.data = response.data;
        this.lastFetch = Date.now();
        return this.data;
      } catch (error) {
        console.error('Failed to fetch insights:', error);
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
});
